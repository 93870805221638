// Norwegian
export default{
    form: {
        startSurveyButton: "La oss starte",
        nextButton: "Neste",
        submitAnswerButton: "Send svar",
        continueButton: "Fortsett",
        finishButton: "Fullfør",
        headerPercentageCompletion: 'fullført',
        headerQuestionsCompleted: 'spørsmål',
        headerDefaultErrorMessage: 'Ups. Noe gikk galt.',
        brandingPoweredBy: 'Drevet av',
        brandingPromoWhySurvey: 'Hvorfor en undersøkelse når du kan bruke <b>{product}</b>?',
        brandingPromoGetStarted: 'Kom i gang i dag!',
        submissionPending: "Sender inn voiceform. Vennligst ikke lukk nettleservinduet.",
        submissionCanCloseWindow: "Din voiceform er sendt inn. Du kan lukke vinduet nå 👍",
        mediaAttachmentOverlayCloseButton: "Lukk",
        expiredFormHeader: "Takk for at du stakk innom!",
        expiredFormMessage: "Det ser ut til at denne undersøkelsen har utløpt. Vennligst kontakt voiceform-skaperen for å informere dem.",
        notPublishedFormHeader: "Voiceform er ikke publisert!",
        notPublishedFormMessage: "Det ser ut til at denne undersøkelsen ikke er publisert. Vennligst kontakt voiceform-skaperen for å informere dem.",
        notPublishedFormRetryButton: "Prøv igjen",
        errorFormHeader: "Oops!",
        errorFormMessage: "Noe kan ha gått galt. Vennligst prøv igjen senere.",
        errorFormRetryButton: "Prøv igjen",
        emptyFormHeader: "Takk for at du stakk innom!",
        emptyFormMessage: "Ups! Det ser ut til at dette skjemaet er tomt uten spørsmål eller felter å fylle ut. Vi beklager bryderiet. Ta gjerne kontakt med skaperen av dette skjemaet for å informere om problemet. Takk for forståelsen og samarbeidet!",

        restoreFormHeader: "Det ser ut til at du har et skjema under arbeid!",
        restoreFormMessage: "Vil du fortsette der du slapp?",
        restoreFormButton: "Fortsett skjema",
        restoreFormButtonNew: "Start på nytt",

        audioPermissionHeader: "Vil du svare med stemmen?",
        audioPermissionMessage: "Å snakke vil gjøre det raskt og enkelt å svare.",
        audioPermissionAcceptButton: "Ja, det vil jeg",
        audioPermissionDenyButton: "Nei, takk",
        audioPermissionErrorHeader: "Mikrofon er ikke tilgjengelig",
        audioPermissionErrorMessage: "Vennligst sjekk nettleserens tillatelser for å aktivere tilgang til mikrofonen.",
        audioPermissionErrorButton: "OK",
        audioRecorderFailedUpload: "Kunne ikke laste opp opptaket",
        silenceDetectorError: "Vi kan ikke høre deg. Vennligst sjekk mikrofoninnstillingene dine. Tips: Det kan være din eksterne mikrofon.",
        audioRecorderButtonRecord: "Start opptak",
        audioRecorderButtonRecordMore: "Ta opp mer",
        voiceResponseSelectionMessage: "Velg hvordan du vil svare...",
        voiceResponseSelectionOrDivider: "eller",
        voiceResponseTextInputPlaceholder: "Skriv svar",
        voiceResponseTextInputCharacterCounterMinMessage: "Vennligst skriv minst {min} tegn",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} tegn (minst {min} tegn)",
        voiceResponseTextInputCharacterValidationMessage: "{count} tegn (minst {min} tegn)",

        // checkbox
        checkboxValidationTooFew: "Du må velge minst {min} alternativ(er)",
        checkboxValidationTooMany: "Du må velge maksimalt {max} alternativ(er)",
        checkboxNoneOfTheAboveOption: "Ingen av de ovennevnte",
        checkboxInvalidAnswer: "Ugyldig svar.",

        // datepicker
        datePickerPlaceholder: "Velg dato",

        // dropdown
        // email
        emailLabel: "E-post",
        emailInvalid:"Ugyldig e-post.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Ta opp video",
        fileUploadPluginScreenCast: "Skjermopptak",
        fileUploadVideoImportFilesDefault: 'Ta opp eller last opp en video via:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Velg et videoopptaksalternativ:',
        fileUploadVideoImportFilesNoCamera: 'Trykk på en knapp for å ta opp skjermvideo',
        fileUploadVideoImportFilesNoScreenCast: 'Trykk på en knapp for å ta opp video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Ingen alternativer tilgjengelige for å ta opp video',

        // matrix
        matrixValidationMessage: "Svaret er gyldig.",
        matrixRow: "Rad",
        matrixColumn: "Kolonne",
        matrixRowRequired: "Raden {rowTitle} er påkrevd.",
        matrixRadioGroup: "Radio-gruppe",
        matrixCheckboxGroup: "Checkbox-gruppe",
        matrixGroupRequired: "For raden {rowTitle}. {type} {groupTitle} er påkrevd.",
        matrixColumnRequired: "For raden {rowTitle}. Kolonnen {columnTitle} er påkrevd.",
        matrixColumnInvalid: "For raden {rowTitle}. Kolonnen {columnTitle} er ugyldig.",
        matrixRequired: "Påkrevd.",
        matrixNumericMustBeNumber: "Må være et tall.",
        matrixNumericMustBeGreaterThenMin: "Må være større enn {min}.",
        matrixNumericMustBeLessThenMax: "Må være mindre enn {max}.",
        matrixNumericMustBeInteger: "Må være et heltall.",
        matrixNumericInvalidNumber: "Ugyldig tall.",

        // name
        nameLabel: "Navn",
        nameInvalid: "Angi fornavn og etternavn.",

        // nps
        npsNotLikely: "Svært usannsynlig",
        npsExtremelyLikely: "Svært sannsynlig",

        // numeric input
        numericInputRequired: "Påkrevd.",
        numericInputMustBeNumber: "Må være et tall.",
        numericInputMustBeGreaterThenMin: "Må være større enn {min}.",
        numericInputMustBeLessThenMax: "Må være mindre enn {max}.",
        numericInputMustBeInteger: "Må være et heltall.",
        numericInputInvalidNumber: "Ugyldig tall.",
        numericInputPlaceholder: "Skriv inn tall",

        // phone
        phoneInvalid: "Telefonnummeret er ugyldig.",
        phoneCountrySelectorLabel: 'Landskode',
        phoneCountrySelectorError: 'Velg land',
        phoneNumberLabel: 'Telefonnummer',
        phoneExample: 'Eksempel:',

        // boolean
        booleanYesLabel: "Ja",
        booleanNoLabel: "Nei",

        //questionStep
        questionStepAudioQuestionLabel: "Lydspørsmål",

        // errors
        invalidPhoneNumber: "{phone} er et ugyldig telefonnummer.",
        invalidEmail: "{email} er en ugyldig e-postadresse.",
        questionIsRequired: "Spørsmålet er påkrevd.",
        answerIsNotValid: "Svaret er ikke gyldig.",
        unfinishedProbingDialogError: "Vennligst fullfør dialogen.",
        cannotResumePartialResponse: "Kan ikke fortsette delvis svar.",
        failedToSubmitForm: "Kunne ikke sende skjema. Sjekk internettforbindelsen og prøv igjen.",

        //language picker
        searchLanguage: "Søk språk",
    }
}
